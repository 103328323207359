var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"item-box"},[(_vm.chartData)?_c('btc-chart',{key:_vm.data.value,ref:"chart",attrs:{"data":_vm.chartData,"unit":_vm.unit,"is-en-expire":_vm.isEnExpire,"title":'Cattle Slaughter -- ' + _vm.data.label},nativeOn:{"click":function($event){return _vm.handleCharts.apply(null, arguments)}}}):_vm._e()],1),_c('div',{staticClass:"item-box",staticStyle:{"padding":"30px"}},[_c('div',{staticClass:"selector-box"},[(_vm.startDate&&_vm.endDate)?_c('datetime',{key:_vm.data.value,attrs:{"start-date":_vm.startDate,"end-date":_vm.endDate},on:{"update:startDate":function($event){_vm.startDate=$event},"update:start-date":function($event){_vm.startDate=$event},"update:endDate":function($event){_vm.endDate=$event},"update:end-date":function($event){_vm.endDate=$event},"tap-start":function($event){return _vm.handleTapDate($event, 'StartDate')},"tap-end":function($event){return _vm.handleTapDate($event, 'EndDate')}}}):_vm._e()],1),_c('btc-table-header',{attrs:{"data":_vm.getHeader()}}),_c('btc-table',{attrs:{"data":_vm.list},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(_vm.isPlantSlaughter)?_c('btc-table-cell',{attrs:{"data":row,"index":index},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cell = ref.cell;
var index = ref.index;
return [(cell.PlantNo==='Total')?_c('span',[_vm._v("*")]):_c('span',[_vm._v(_vm._s(index))])]}}],null,true)}):_vm._e(),(_vm.isPlantSlaughter)?_c('btc-table-cell',{attrs:{"data":row},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cell = ref.cell;
return [_c('span',[_vm._v(_vm._s(cell.PlantNo))])]}}],null,true)}):_vm._e(),(_vm.isCountrySlaughter)?_c('btc-table-cell',{attrs:{"data":row},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cell = ref.cell;
return [_c('span',[_vm._v(_vm._s(cell.Year))])]}}],null,true)}):_vm._e(),(_vm.isCountrySlaughter)?_c('btc-table-cell',{attrs:{"data":row},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cell = ref.cell;
return [_c('span',[_vm._v(_vm._s(cell.Month))])]}}],null,true)}):_vm._e(),(_vm.isOther)?_c('btc-table-cell',{attrs:{"data":row},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cell = ref.cell;
return [_c('span',[_vm._v(_vm._s(cell.Origin))])]}}],null,true)}):_vm._e(),_c('btc-table-cell',{attrs:{"data":row},nativeOn:{"click":function($event){return _vm.isLoginEnExpire.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cell = ref.cell;
return [_c('span',[_vm._v(_vm._s(cell.Volume))])]}}],null,true)}),(_vm.isPlantSlaughter)?_c('btc-table-cell',{attrs:{"data":row},nativeOn:{"click":function($event){return _vm.isLoginEnExpire.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cell = ref.cell;
return [_c('b-form-checkbox',{attrs:{"value":cell.PlantNo,"disabled":(_vm.checkbox.length === 1 && _vm.checkbox[0] === cell.PlantNo) || !_vm.isEnExpire || _vm.isLogin,"plain":""},on:{"change":function($event){return _vm.handleChangeCheckbox($event, 'PlantNo', 'GetBeefWebDataThreeChart')}},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})]}}],null,true)}):_vm._e(),(_vm.isOther)?_c('btc-table-cell',{attrs:{"data":row},nativeOn:{"click":function($event){return _vm.isLoginEnExpire.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cell = ref.cell;
return [_c('b-form-checkbox',{attrs:{"value":cell.Origin,"disabled":(_vm.checkbox2.length === 1 && _vm.checkbox2[0] === cell.Origin) || !_vm.isEnExpire || _vm.isLogin,"plain":""},on:{"change":function($event){return _vm.handleChangeCheckbox($event, 'Origin', 'GetBeefWebDataFiveChart')}},model:{value:(_vm.checkbox2),callback:function ($$v) {_vm.checkbox2=$$v},expression:"checkbox2"}})]}}],null,true)}):_vm._e()]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }