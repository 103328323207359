<template>
  <div class="item-box">
    <div style="height: 20px"/>
    <div v-for="(item, index) in items" :key="index" @click="handleToggle(item, index)">
      <div :class="{ active: isCurrent(index) }" class="item hand">
        <img v-if="isCurrent(index)" :src="item.icon[1]">
        <img v-if="!isCurrent(index)" :src="item.icon[0]">
        <span>{{ item.label }}</span>
        <div :class="{ 'arrow-down': isCurrent(index), 'ac-2': isCurrent(index), hide: item.children.length === 0 }" class="btc-arrow arrow-up ac-1" ></div>
      </div>
      <div>
        <div v-show="isCurrent(index) && item.children.length > 0" v-for="(child, cindex) in item.children" :key="child.value" class="item-child" @click.stop="handleClickItem(child, cindex)">
          <dotline :is-last="cindex === item.children.length - 1" :is-active="currentChild === cindex"/>
          <span class="hand" :class="{ cactive: currentChild === cindex }">{{ child.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dotline from './components/dotline'

export default {
  name: 'BtcDataSidebar',
  components: {
    Dotline
  },
  data () {
    return {
      current: 0,
      currentChild: -1,
      items: [
        {
          label: 'Beef Exports to China',
          value: 'BeefExportsToChina',
          icon: [
            require('@/assets/image/data/beef_export_to_china.png'),
            require('@/assets/image/data/beef_export_to_china1.png')
          ],
          children: []
        },
        {
          label: 'Global Beef Exports',
          value: 'GlobalBeefExports',
          icon: [
            require('@/assets/image/data/global_beef_exports.png'),
            require('@/assets/image/data/global_beef_exports1.png')
          ],
          children: [
            { label: 'Brazil', value: '巴西' },
            { label: 'Argentina', value: '阿根廷' },
            { label: 'Australia', value: '澳大利亚' },
            { label: 'Uruguay', value: '乌拉圭' },
            { label: 'New Zealand', value: '新西兰' }
          ]
        },
        {
          label: 'Cattle Slaughter',
          value: 'CattleSlaughter',
          icon: [
            require('@/assets/image/data/slaughter_by_origin.png'),
            require('@/assets/image/data/slaughter_by_origin1.png')
          ],
          children: [
            { label: 'Uruguay', value: '乌拉圭' },
            { label: 'Argentina', value: '阿根廷' },
            { label: 'Australia', value: '澳大利亚' },
            { label: 'New Zealand', value: '新西兰' },
            { label: 'Brazil', value: '巴西' },
            { label: 'Comparision', value: '以上对比' }
          ]
        },
        {
          label: 'India / Hong Kong',
          value: 'IndiaHongKong',
          icon: [
            require('@/assets/image/data/beef_imports_exports.png'),
            require('@/assets/image/data/beef_imports_exports1.png')
          ],
          children: [
            { label: 'Indian Beef Exports', value: '印度' },
            { label: 'Hong Kong Beef Imports', value: '中国香港' }
          ]
        },
        {
          label: 'China\'s Beef Imports',
          value: 'ChinasBeefImports',
          icon: [
            require('@/assets/image/data/china_beef_imports.png'),
            require('@/assets/image/data/china_beef_imports1.png')
          ],
          children: [
            { label: 'Updating', value: '实时更新' },
            { label: 'Historical Query', value: '历史查询' }
          ]
        }
      ]
    }
  },
  methods: {
    isCurrent (index) {
      return this.current === index
    },
    handleToggle (item, index) {
      this.currentChild = -1
      this.current = this.current !== index ? index : -1
      this.$emit('tab-outer', item)
    },
    handleClickItem (item, index) {
      this.currentChild = index
      this.$emit('tab-inner', item)
    }
  }
}
</script>

<style lang="scss" scoped>
$dot-line-color: #e8e8e7;
.item-box{
  color: #666666;
}
.item {
  height: 40px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 10px;
  font-size: 14px;
}

.item > img {
  width: 19px;
  height: 16px;
}

.item > span {
  width: 160px;
}

.item-child {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding-left: 15px;
  font-size: 14px;
}

.item-child:first-child {
  margin-top: 8px;
}

.item-child > span {
  margin-left: 20px;
}

.active {
  color: #024579;
  background-color: #e4f2ff;
}
.cactive{
  color: #333333;
  font-size: 14px;
  font-weight: bold;
}
</style>
